import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, {useState} from "react";


const BlogCard = (props) => {
 
    const data = props.data
    const imgUrl = data.featuredImage?.node.mediaItemUrl

    const months = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun",
    "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"];
    
    const date = new Date(data.date)
    const DateString = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`

    return(
        <div className="grid grid-cols-3 ">
           <Link to={data.uri} className='col-span-1 h-full '><img src={imgUrl} alt={data.altText} className='object-cover h-48 max-h-full w-full'></img></Link>
           <div className='col-span-2 py-4 px-0 pl-8 lg:px-8 text-blue h-full flex flex-col justify-center'>
               <span className="text-sm">{data.categories.nodes.map((c, i)=>{return(<span>{c.name}{i+1 < data.categories.nodes.length ? ', ' : null}</span> )})} &bull; {DateString}</span>
               <Link to={data.uri}><h3 className="mt-4">{data.title}</h3></Link>
           </div>
        </div>
    )
}

export default BlogCard;