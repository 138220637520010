import React, { useContext } from "react";
import Container from "../components/Container/Container";
import Seo from "../components/Seo/Seo";
import G from "../images/groenhout_g.png"
import Image from "../images/groenhout-assen-1.jpg"
import { graphql, useStaticQuery } from "gatsby";
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew";
import BlogGrid from "../components/Blog/BlogGrid";

const BlogPage = () => {

    const data = useStaticQuery(graphql`
        query LatestBlogQuery {
            allWpPost(sort: {fields: date, order: DESC}) {
                edges {
                  node {
                    featuredImage {
                      node {
                        altText
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
        }
    `) 


    const image = data.allWpPost.edges[0].node.featuredImage.node.mediaItemUrl

    return(
        <>
            <Seo title={'Blog | Groenhout Makelaars'}></Seo>
            <SmallHeroNew title="Bekijk de laatste ontwikkelingen" desc="Lees onze inzichten over de huidige woningmarkt." overlay={true} overlayImage={G} image={image !== null ? image : Image}></SmallHeroNew>
            <Container styles={'px-5 lg:px-0 py-16'}>
                <BlogGrid limit={false}></BlogGrid> 
            </Container>
        </>
    )
}

export default BlogPage;